var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('search-form',{on:{"submit":_vm.handleSearchFormSubmit}}),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_c('v-icon',{staticClass:"elevation-6 pink lighten-1 pa-2 mr-3",attrs:{"small":"","color":"white"}},[_vm._v(" mdi-format-list-bulleted-square ")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.equipment.list'))+" "),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.detailsDatas,"item-key":"id","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.gender." + (item.gender.toLowerCase()))))+" ")]}},{key:"item.age",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.age)+" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.temperature.detailAge'))+" ")]}},{key:"item.institution",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.institutionName)+" ")]}},{key:"item.temperature",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.temperature)+" ℃ ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.time,'YYYY-MM-DD HH:mm'))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-pagination',{attrs:{"length":_vm.queryParams.params.pageCount},model:{value:(_vm.queryParams.params.page),callback:function ($$v) {_vm.$set(_vm.queryParams.params, "page", $$v)},expression:"queryParams.params.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }