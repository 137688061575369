<template>
  <v-container fluid>
    <search-form @submit="handleSearchFormSubmit"/>
    <v-card class="mt-5">
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2 mr-3"
        >
          mdi-format-list-bulleted-square
        </v-icon>
        {{$vuetify.lang.t('$vuetify.equipment.list')}}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="detailsDatas"
          item-key="id"
          hide-default-footer
        >
          <template
            v-slot:item.gender="{ item }"
          >
             {{ $vuetify.lang.t(`$vuetify.gender.${ item.gender.toLowerCase() }`) }}
          </template>

          <template
            v-slot:item.age="{ item }"
          >
            {{ item.age }} {{ $vuetify.lang.t('$vuetify.temperature.detailAge') }}
          </template>

          <template
            v-slot:item.institution="{ item }"
          >
           {{ item.institutionName }}
          </template>


          <template
            v-slot:item.temperature="{ item }"
          >
           {{ item.temperature }} &#8451;
          </template>

          <template
            v-slot:item.time="{ item }"
          >
           {{ item.time | moment('YYYY-MM-DD HH:mm') }}
          </template>

          <template
            v-slot:item.time="{ item }"
          >
           {{ item.time | moment('YYYY-MM-DD HH:mm') }}
          </template>

        </v-data-table>

        <v-pagination
          v-model="queryParams.params.page"
          :length="queryParams.params.pageCount"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import { snakeToCamel } from '@/utils';

  export default {
    components: {
      SearchForm: () => import('./SearchForm')
    },

    data () {
      return {
        selected: [],
        deleteDialog: false,
        recyclingDialog: false,
        editItem: null,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          }
        }
      }
    },

    computed: {

      ...mapGetters({
        detailsDatas: 'temperatureMonitor/detailsDatas'
      }),

      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.common.name'),
            align: 'start',
            sortable: false,
            value: 'realName',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.gender'),
            align: 'start',
            sortable: false,
            value: 'gender',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.age'),
            align: 'start',
            sortable: false,
            value: 'age',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.identityCard'),
            align: 'start',
            sortable: false,
            value: 'credentialNum',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.idAddress'),
            align: 'start',
            sortable: false,
            value: 'idCardAddress',
          },{
            text: this.$vuetify.lang.t('$vuetify.temperature.detailInstitution'),
            align: 'start',
            sortable: false,
            value: 'institution',
          },{
            text: this.$vuetify.lang.t('$vuetify.temperature.detailTemperature'),
            align: 'start',
            sortable: false,
            value: 'temperature',
          },{
            text: this.$vuetify.lang.t('$vuetify.temperature.detailTime'),
            align: 'start',
            sortable: false,
            value: 'time',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function() {
            /*
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.equipmentQuery({params: params, data: this.queryParams.data }).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
            })
            */
            this.getDTemperatureDetailsData()
         }
      }
    },


    created () {
      this.getDTemperatureDetailsData()
    },

    methods: {

      ...mapActions({
        queryTemperatureDetailsData: 'temperatureMonitor/queryTemperatureDetailsData'
      }),

      snakeToCamel,

      handleSearchFormSubmit(data) {
        this.getDTemperatureDetailsData(data)
      },

      getDTemperatureDetailsData (data) {

        if (data) {
          this.queryParams.data = data
          this.queryParams.params.page = 1
        }
        let params = Object.assign({}, this.queryParams.params)
        params.page = params.page - 1
        this.queryTemperatureDetailsData({params: params, data: this.queryParams.data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
        })
      },

      handleDistribute  () {
        this.$router.push( {name: 'equipmentDistribute'})
      },

      handleAddClick () {
        console.log('add')
      },

      handleDeleteClick () {
        this.deleteDialog = true
      },

      handleDeleteDialog () {
        this.equipmentDelete(this.selected[0]).then( () => {
          this.deleteDialog = false
        })
      },

      handleRecyclingItem (item) {
        this.editItem = item
        this.recyclingDialog = true
      },

      handleRecyclingDialog () {
        this.equipmentRecycling({id: this.editItem.id, params: { pt_id: this.editItem.patient.id }}).then( () => {
          this.recyclingDialog = false
        })
      }
    }
  }
</script>
